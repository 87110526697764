<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1120" persistent scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Detailed status
        </v-card-title>

        <v-card-text class="mt-3">
          <v-data-table
            :headers="headers"
            :items="entityCollection"
            item-key="item_entity_id"
            class="elevation-1"
            @click:row="handleOpenEntitySheetDialog"
          >
            <template v-slot:item.status="{ item }">
              <span v-if="statuses.system">{{
                $t("ADJUSTMENT.status_" + item.status)
              }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon v-if="item.status != 8" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <DeleteModalDialog
            :dialogDelete="dialogDelete"
            @closeDelete="handleCloseDelete"
            @deleteItemConfirm="handleDeleteItemConfirm"
          >
          </DeleteModalDialog>
          <EntitySheetDialog
            :entitySheetId="entitySheetId"
            :entityDialog="entityDialog"
            @handleCloseEntitySheetDialog="handleCloseEntitySheetDialog"
          >
          </EntitySheetDialog>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseModalForm">
            Cancel
          </v-btn>

          <v-btn color="blue darken-1" text @click="handleSaveEntities">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import EntitySheetDialog from "@/view/components/EntitySheetDialog";

export default {
  name: "SODetailedDialog",

  props: ["salesOrder", "dialog"],

  components: {
    DeleteModalDialog,
    EntitySheetDialog,
  },

  data() {
    return {
      urls: URL,
      prevSelected: [],
      entityCollection: [],
      entityCollectionOrigi: [],
      dialogDelete: false,
      editedItem: null,

      headers: [
        { text: "Entity ID", value: "item_entity_id" },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "name" },
        { text: "Serial", value: "serial_number" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        { text: "State", value: "status" },
        { text: "Actions", value: "actions" },
      ],

      entitySheetId: null,
      entityDialog: false,
    };
  },

  computed: {
    ...mapGetters(["statuses"]),

    showCountError() {
      return this.selected.length > this.numOfPcs;
    },
  },

  watch: {
    dialog(val) {
      this.resetFormData();
      if (val) {
        ApiService.get(`sales/salesOrder/${this.salesOrder.id}/entities`).then(
          ({ data }) => {
            this.entityCollection = data.map((i) => {
              i.name = this.$helpers.getTranslated(i.translations).name;
              i.quantityUnit = this.$helpers.getTranslated(
                i.quantity_unit_translations
              ).name;
              return i;
            });

            this.entityCollectionOrigi = [...this.entityCollection];
          }
        );
      }
    },
  },

  methods: {
    resetFormData() {
      this.entityCollection = [];
      this.editedItem = null;
    },

    handleDeleteItemConfirm() {
      this.entityCollection.splice(this.editedItem, 1);

      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.editedItem = null;
      this.dialogDelete = false;
    },

    handleSaveEntities() {
      let prevIds = this.entityCollectionOrigi.map((i) => i.item_entity_id);

      let ids = this.entityCollection.map((i) => i.item_entity_id);

      let intersectionIds = prevIds.filter((x) => !ids.includes(x));

      ApiService.post(`sales/salesOrder/${this.salesOrder.id}/entities`, {
        entities: intersectionIds,
      }).then(() => {
        this.handleCloseModalForm();
      });
    },

    handleOpenEntitySheetDialog(item) {
      if (!this.dialogDelete) {
        this.entitySheetId = item.item_entity_id;
        this.entityDialog = true;
      }
    },

    handleCloseEntitySheetDialog() {
      this.entityDialog = false;
      this.entitySheetId = null;
    },

    handleCloseModalForm() {
      this.selected = [];
      this.resetFormData();
      this.$emit("handleCloseSODetailedDialog");
    },

    deleteItem(item) {
      this.editedItem = this.entityCollection.indexOf(item);
      this.dialogDelete = true;
    },
  },
};
</script>
