var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"max-height":"80%"},attrs:{"width":"1150","hide-overlay":"","scrollable":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.activityDialog),callback:function ($$v) {_vm.activityDialog=$$v},expression:"activityDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Activity log")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('closeActivityModal')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-timeline',_vm._l((_vm.actions),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"color":"#ccccd5","large":""},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(item["user_name"] == "not_logged_in" ? _vm.$t("ACTIVITY.not_logged_id") : item.user_name)+" ")]),_c('span',[_vm._v(_vm._s(item.created_at))])]},proxy:true}],null,true)},[_c('v-card',{staticClass:"elevation-2"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.activity))]),_c('v-card-text',[(item.changes && item.changes.length != 0)?_c('table',{staticStyle:{"width":"100%"}},[(item.action != 1)?_c('tr',[_c('th',[_vm._v("Changed field")]),_c('th',[_vm._v("Old value")]),_c('th',[_vm._v("New value")])]):_vm._e(),_vm._l((Object.keys(item.changes)),function(change){return _c('tr',{key:change},[(
                      item.action != 1 ||
                      change == 'so_number' ||
                      change == 'status'
                    )?_c('td',[_vm._v(" "+_vm._s(_vm.$t("FORMINPUTNAMES." + change))+" ")]):_vm._e(),(
                      item.action != 1 ||
                      change == 'so_number' ||
                      change == 'status'
                    )?_c('td',[_vm._v(" "+_vm._s(change != "status" ? item.changes[change]["old"] : _vm.salesOrderStatuses[item.changes[change]["old"]])+" ")]):_vm._e(),(
                      item.action != 1 ||
                      change == 'so_number' ||
                      change == 'status'
                    )?_c('td',[_vm._v(" "+_vm._s(change != "status" ? item.changes[change]["new"] : _vm.salesOrderStatuses[item.changes[change]["new"]])+" ")]):_vm._e()])})],2):_vm._e(),(item.to)?_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',[_c('b',[_vm._v("Recipient:")])]),_c('td',[_vm._v(_vm._s(item.to))])])]):_vm._e()])],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }