<template>
  <div class="text-center">
    <v-dialog v-model="SOEntityDialog" width="960" persistent scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select entities
        </v-card-title>

        <v-card-text class="mt-3">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="entityCollection"
            item-key="item_entity_id"
            show-select
            class="elevation-1"
          >
            <template v-slot:header.data-table-select="{ on, props }">
              <v-simple-checkbox
                v-if="1 == 2"
                color="purple"
                v-bind="props"
                v-on="on"
              />
            </template>
            <template v-slot:item.status="{ item }">
              <span v-if="statuses.system">{{
                $t("ADJUSTMENT.status_" + item.status)
              }}</span>
            </template>
            <template v-slot:item.transaction="{ item }">
              <span v-if="item.transactions && item.transactions.length > 0">{{
                getTransaction(item.transactions)
                  ? getTransaction(item.transactions).transaction_number
                  : ""
              }}</span>
            </template>
            <template v-slot:item.scheduled_date="{ item }">
              <span v-if="item.transactions && item.transactions.length > 0">{{
                getTransaction(item.transactions)
                  ? getTransaction(item.transactions).scheduled_date
                  : ""
              }}</span>
            </template>
          </v-data-table>
          <span v-if="showCountError" style="color: red"
            >You have already selected all the necsessary entities!</span
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseModalForm">
            Cancel
          </v-btn>

          <v-btn
            v-if="!showCountError"
            color="blue darken-1"
            text
            @click="handleSaveEntities"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "SOEntityDialog",

  props: ["salesOrderItemId"],

  data() {
    return {
      SOEntityDialog: false,
      urls: URL,
      selected: [],
      prevSelected: [],
      entityCollection: [],
      numOfPcs: 0,

      headers: [
        { text: "Entity ID", value: "item_entity_id" },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "name" },
        { text: "Serial", value: "serial_number" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        { text: "State", value: "status" },
        { text: "Transaction", value: "transaction" },
        { text: "Scheduled date", value: "scheduled_date" },
      ],
    };
  },

  computed: {
    ...mapGetters(["statuses"]),

    showCountError() {
      return this.selected.length > this.numOfPcs;
    },
  },

  watch: {
    salesOrderItemId(val) {
      if (val) {
        ApiService.get("sales/salesOrder/salesOrderItem/" + val).then(
          ({ data }) => {
            console.log(data);
            this.numOfPcs = data.num_of_pcs;

            this.entityCollection = data.entities.map((i) => {
              i.name = this.$helpers.getTranslated(i.translations).name;
              i.quantityUnit = this.$helpers.getTranslated(
                i.quantity_unit_translations
              ).name;
              return i;
            });

            if (this.numOfPcs == 0) {
              this.handleCloseModalForm();
            } else if (this.entityCollection.length == 0) {
              Swal.fire({
                title: "",
                text: "Entity not found!",
                icon: "error",
              });
            } else {
              this.SOEntityDialog = true;
            }
          }
        );
      } else {
        this.SOEntityDialog = false;
      }
    },

    selected(val) {
      this.reserveUnreserve(val);
    },
  },

  methods: {
    resetFormData() {
      this.numOfPcs = 0;
      this.selected = [];
      this.entityCollection = [];
      this.prevSelected = [];
    },

    reserveUnreserve(entities) {
      let ids = entities.map((i) => i.item_entity_id);

      let prevIds = this.prevSelected.map((i) => i.item_entity_id);

      let type = null;

      let intersectionIds = [];

      if (ids.length > prevIds.length) {
        type = "reserve";
        intersectionIds = ids.filter((x) => !prevIds.includes(x));
      }

      if (ids.length < prevIds.length) {
        type = "unreserve";
        intersectionIds = prevIds.filter((x) => !ids.includes(x));
      }

      if (type) {
        ApiService.post("sales/salesOrder/itemEntity/" + type, {
          ids: intersectionIds,
        })
          .then(() => {
            this.prevSelected = [...this.selected];
          })
          .catch(() => {
            this.selected = [...this.prevSelected];
            Swal.fire({
              title: "",
              text: "The entity is reserved!",
              icon: "error",
            });
          });
      }
    },

    getTransaction(transactions) {
      let tr = transactions.find(
        (i) =>
          i.transaction_type ==
            "Modules\\Procurement\\Entities\\PurchaseOrder" ||
          i.transaction_type == "Modules\\Manufacture\\Entities\\Project"
      );

      if (tr) return tr;
      else return null;
    },

    handleSaveEntities() {
      if (this.selected.length > 0) {
        let ids = this.selected.map((i) => i.item_entity_id);
        console.log(this.salesOrderItemId);
        ApiService.post(
          `sales/salesOrder/salesOrderItem/${this.salesOrderItemId}`,
          {
            entities: ids,
          }
        )
          .then(({ data }) => {
            this.handleCloseModalForm(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    handleCloseModalForm(data = null) {
      this.selected = [];
      this.reserveUnreserve(this.selected);
      this.resetFormData();
      this.$emit("handleCloseSOEntityDialog", data);
    },
  },
};
</script>
