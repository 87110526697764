<template>
  <v-dialog
    v-model="activityDialog"
    width="1150"
    hide-overlay
    scrollable
    persistent
    transition="dialog-bottom-transition"
    style="max-height: 80%"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Activity log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('closeActivityModal')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-btn dark text @click="activityDialog = false">
                        Save
                      </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>

      <!-- <v-card-text style="max-height: 90%"> -->
      <v-card-text class="mt-5">
        <v-timeline>
          <v-timeline-item
            v-for="item in actions"
            :key="item.id"
            color="#ccccd5"
            large
          >
            <template v-slot:opposite>
              <p>
                {{
                  item["user_name"] == "not_logged_in"
                    ? $t("ACTIVITY.not_logged_id")
                    : item.user_name
                }}
              </p>
              <span>{{ item.created_at }}</span>
            </template>
            <v-card class="elevation-2">
              <v-card-title class="text-h5"> {{ item.activity }}</v-card-title>
              <!-- <v-card-text v-if="item.action != 1"> -->
              <v-card-text>
                <table
                  style="width: 100%"
                  v-if="item.changes && item.changes.length != 0"
                >
                  <tr v-if="item.action != 1">
                    <th>Changed field</th>
                    <th>Old value</th>
                    <th>New value</th>
                  </tr>
                  <tr v-for="change in Object.keys(item.changes)" :key="change">
                    <td
                      v-if="
                        item.action != 1 ||
                        change == 'so_number' ||
                        change == 'status'
                      "
                    >
                      {{ $t("FORMINPUTNAMES." + change) }}
                    </td>
                    <td
                      v-if="
                        item.action != 1 ||
                        change == 'so_number' ||
                        change == 'status'
                      "
                    >
                      {{
                        change != "status"
                          ? item.changes[change]["old"]
                          : salesOrderStatuses[item.changes[change]["old"]]
                      }}
                    </td>
                    <td
                      v-if="
                        item.action != 1 ||
                        change == 'so_number' ||
                        change == 'status'
                      "
                    >
                      {{
                        change != "status"
                          ? item.changes[change]["new"]
                          : salesOrderStatuses[item.changes[change]["new"]]
                      }}
                    </td>
                  </tr>
                </table>

                <table style="width: 100%" v-if="item.to">
                  <tr>
                    <td><b>Recipient:</b></td>
                    <td>{{ item.to }}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <pre
          >{{ actions }}
            </pre
        > -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export const ENDPOINT = "sales/salesOrder/";

const ACTIONS = [
  "",
  "SO Created",
  "Update",
  "Change status",
  "Approved",
  "Contracted",
  "Revoked",
  "Email sent",
  "Lost",
  "Unlost",
  "New version",
  "Delete",
  "Ordered",
  "Offered",
  "Delivered",
  "Send new offered email",
  "Paid via OTP Simple",
];

export default {
  name: "ActivityModal",
  props: ["activityDialog", "salesOrderModel", "salesOrderStatuses"],
  data() {
    return {
      actions: [],
    };
  },
  watch: {
    activityDialog(val) {
      if (val) {
        ApiService.get(ENDPOINT + this.salesOrderModel.id + "/activity")
          .then(({ data }) => {
            // console.log(data);
            this.actions = data.map((item) => {
              item.activity = ACTIONS[item.action];
              let properties = item.properties;

              if (properties) {
                item.changes = properties.changes;
                item.to = properties.to;
              } else {
                item.changes = null;
                item.to = null;
              }
              return item;
            });
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },
  },
  methods: {
    handleCloseActivityModal() {
      this.$emit("closeActivityModal");
    },
  },
};
</script>
