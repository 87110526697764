<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="sendEmailDialog"
        transition="dialog-bottom-transition"
        max-width="960"
        persistent
        scrollable
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Send in email</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon dark @click="handleCloseModalForm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form v-model="formValid" ref="form">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!-- <v-text-field
                      v-model="formModel.recipient"
                      label="Recipient"
                      :rules="recipientRules"
                      :id="dynamicID"
                      :error-messages="messages['recipient']"
                      @keyup="messages['recipient'] = ''"
                    ></v-text-field> -->
                    <v-combobox
                      v-model="formModel.recipient"
                      chips
                      clearable
                      label="Recipient"
                      multiple
                      solo
                      :rules="bccRules"
                      :error-messages="messages['recipient']"
                      @keyup="messages['recipient'] = ''"
                      disabled
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-combobox
                      v-model="formModel.cc"
                      chips
                      clearable
                      label="Cc"
                      multiple
                      solo
                      :rules="bccRules"
                      :error-messages="messages['cc']"
                      @keyup="messages['cc'] = ''"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-combobox
                      v-model="formModel.bcc"
                      chips
                      clearable
                      label="Bcc"
                      multiple
                      solo
                      :rules="bccRules"
                      :error-messages="messages['bcc']"
                      @keyup="messages['bcc'] = ''"
                    >
                    </v-combobox>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="formModel.subject"
                      label="Subject"
                      :rules="subjectRules"
                      :id="dynamicID"
                      :error-messages="messages['subject']"
                      @keyup="messages['subject'] = ''"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="formModel.invocation"
                      label="Invocation"
                      :rules="subjectRules"
                      :id="dynamicID"
                      :error-messages="messages['invocation']"
                      @keyup="messages['invocation'] = ''"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!-- <v-textarea
                      clearable
                      clear-icon="mdi-close-circle"
                      label="Message"
                      v-model="formModel.message"
                      rows="6"
                      :rules="messageRules"
                      :id="dynamicID"
                      :error-messages="messages['message']"
                      @keyup="messages['message'] = ''"
                    ></v-textarea> -->

                    <label for="uzenet">Message</label>
                    <ckeditor
                      v-model="formModel.message"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="handleCloseModalForm">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleSaveModalForm">
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-overlay :value="loader">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-col>
  </v-row>
</template> 

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import i18n from "@/core/plugins/vue-i18n.js";
// import { mapGetters, mapActions } from "vuex";
const ENDPOINT = "sales/salesOrder/";

export const initialFormData = {
  status: null,
  recipient: [],
  cc: [],
  bcc: [],
  subject: "",
  message: "",
  id: "",
  invocaion: "",
};

export default {
  name: "SendEmailModalForm",
  props: ["sendEmailDialog", "salesOrderModel"],
  data() {
    return {
      // languages: i18nService.languages,
      // selectedLocale: i18nService.languages.find((item) => {
      //   return item.lang == i18nService.getActiveLanguage();
      // }),
      loader: false,
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      messages: {},
      subjectRules: [
        (v) => !!v || "Subject field is required",
        (v) => (v && v.length > 5) || "Input field must be min 6 characters",
      ],
      recipientRules: [
        (v) => !!v || "Recipient field is required",
        (v) => (v && v.length > 5) || "Input field must be min 6 characters",
      ],
      messageRules: [
        (v) => !!v || "Message field is required",
        (v) => (v && v.length > 5) || "Input field must be min 6 characters",
      ],

      bccRules: [
        (v) => {
          let ret = true;
          if (v) {
            var re = /\S+@\S+\.\S+/;
            v.forEach((item) => {
              if (!re.test(item)) ret = "Must be valid email!";
            });
          }
          return ret;
        },
      ],

      chips: [
        "Programming",
        "Playing video games",
        "Watching movies",
        "Sleeping",
      ],

      items: ["Streaming", "Eating"],

      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,
      },
    };
  },
  computed: {
    // ...mapGetters(["userGroupCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    formTitle() {
      return "Send email";
    },
  },
  watch: {
    salesOrderModel: {
      handler: function (val) {
        if (val.customer) {
          this.formModel = Object.assign({}, initialFormData);
          this.formModel.recipient =
            val.customer && val.customer.email ? [val.customer.email] : [];

          this.messages = {};

          let name = "";

          if (
            this.salesOrderModel.contact_name &&
            this.salesOrderModel.contact_name.length > 0
          ) {
            name = this.salesOrderModel.contact_name.trim();
          } else {
            name = this.salesOrderModel.company_name;
          }

          let appLocale = i18nService.getActiveLanguage();
          i18n.locale = this.salesOrderModel.locale;

          this.formModel.id = val.id;
          this.formModel.status = val.status;
          this.formModel.invocation =
            this.$t("EMAIL.invocation") + " " + name + "!";
          if (this.formModel.status < 5) {
            this.formModel.subject = this.$t("EMAIL.subject");
            this.formModel.message =
              this.$t("EMAIL.message1") +
              "\n <br>" +
              this.$t("EMAIL.message2") +
              " " +
              "<b>" +
              this.salesOrderModel.so_number +
              "</b><br>" +
              "\n" +
              this.$t("EMAIL.message3");
          } else {
            this.formModel.subject = this.$t("EMAIL.subject_ordered");
            this.formModel.message =
              this.$t("EMAIL.message1_ordered") +
              "\n<br>" +
              this.$t("EMAIL.message2") +
              " " +
              "<b>" +
              this.salesOrderModel.so_number +
              "</b><br>" +
              "\n" +
              this.$t("EMAIL.message3_ordered");
          }

          i18n.locale = appLocale;

          // if (this.salesOrderModel.locale == "hu") {
          //   this.formModel.bcc = this.$store.getters
          //     .getUserGroupByID(7)
          //     .users.map((item) => item.email);
          // }
          // if (this.salesOrderModel.locale == "ro") {
          //   this.formModel.bcc = this.$store.getters
          //     .getUserGroupByID(8)
          //     .users.map((item) => item.email);
          // }

          // this.formModel.bcc = ["gyula.kis@kismetal.hu"];
          this.formModel.bcc = [];

          if (this.salesOrderModel && this.salesOrderModel.owner_id) {
            let salesPerson = this.$store.getters.getUserByID(
              this.salesOrderModel.owner_id
            );
            if (salesPerson) {
              this.formModel.bcc.push(salesPerson.email);
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // ...mapActions(["fetchUserGroups"]),

    handleCloseModalForm() {
      this.loader = false;
      this.resetErrorMessages();
      this.$emit("closeSendEmailDialog");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      this.$refs.form.validate();
      if (!this.formValid) return;
      this.loader = true;
      if (this.formValid) {
        this.resetErrorMessages();
        ApiService.post(
          ENDPOINT + this.salesOrderModel.id + "/sendEmail",
          model
        )
          .then(() => {
            this.handleCloseModalForm();
          })
          .catch((error) => {
            if (error.response) {
              let errors = error.response.data;
              this.loader = false;
              if (errors) {
                for (let field in errors) {
                  this.setError(field, errors[field][0]);
                }
              }
            }
          });
      }
    },

    handleNew() {
      this.formModel = Object.assign({}, initialFormData);
      this.resetErrorMessages();
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData);
      // this.languages.forEach((language) => {
      //   TRANSLATED_ATRIBUTES.forEach((attribute) => {
      //     errorData["translations." + language.lang + "." + attribute] = "";
      //   });
      // });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "inventory.quantityUnit." + action
      );
    },
  },

  // mounted() {
  //   this.fetchUserGroups();
  // },
};
</script>
